// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import PageWrapper from '../components/PageWrapper';
import AppointmentForm from '../components/AppointmentForm';

const styles = () => ({
  root: {},
  wrapper: {},
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  link: {},
  title: {},
  subtitle: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const NotFoundPage = ({ classes }: Props) => (
  <PageWrapper
    limitWidth
    guttersH
    guttersV
    classes={{ root: classes.root, wrapper: classes.wrapper }}
  >
    <Typography
      variant="h4"
      color="inherit"
      className={classes.title}
      gutterBottom
    >
      <FormattedMessage id="appointmentDialog.title" />
    </Typography>
    <Typography className={classes.subtitle} variant="caption">
      <FormattedMessage id="appointmentDialog.subtitle" />
    </Typography>
    <AppointmentForm />
  </PageWrapper>
);

export default withStyles(styles)(NotFoundPage);
